import moment from 'moment';
import momentTz from 'moment-timezone';
import { AvatarGroup } from '@material-ui/lab';
import {
  IconButton,
  Dialog,
  DialogContent,
  Avatar,
  Box,
  Typography,
  Divider,
  Button,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';

import { useStyles } from './BookingMeetingStyle';
import Colors from '../../../../common/colors';

import CLOSE_ICON from '../../../../img/close-dialog.svg';
import { useContext, useEffect } from 'react';
import { AppContext } from '../../../../contexts';

export interface participants {
  name: string;
  email: string;
  id: number | undefined;
  avatar?: string;
}

export interface meetingInfo {
  start_time: number;
  end_time: number;
  advisors: participants[];
  studentName: string;
  note: string;
}

export const greenTickIc = () => (
  <svg
    width="27"
    height="27"
    viewBox="0 0 23 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.84 1.2434C22.129 1.53251 22.2914 1.92457 22.2914 2.33336C22.2914 2.74215 22.129 3.13421 21.84 3.42332L9.50664 15.7567C9.21754 16.0457 8.82548 16.208 8.41668 16.208C8.00789 16.208 7.61583 16.0457 7.32673 15.7567L1.16006 9.58998C0.879232 9.29922 0.723841 8.90979 0.727353 8.50558C0.730866 8.10136 0.893001 7.71469 1.17884 7.42885C1.46468 7.14301 1.85135 6.98088 2.25557 6.97736C2.65979 6.97385 3.04921 7.12924 3.33997 7.41007L8.41668 12.4868L19.6601 1.2434C19.9492 0.954384 20.3412 0.792023 20.75 0.792023C21.1588 0.792023 21.5509 0.954384 21.84 1.2434Z"
      fill={Colors.GREEN_EIGHT}
    />
  </svg>
);

export default function MeetingBooked({
  openMeetingInfo,
  setOpenMeetingInfo,
  meetingInfo,
  page,
  setOpenCalendar,
  setIsHideScheduleButton,
}: {
  openMeetingInfo: boolean;
  setOpenMeetingInfo: Function;
  meetingInfo: meetingInfo;
  page: string;
  setOpenCalendar: Function;
  setIsHideScheduleButton: Function;
}) {
  const { user } = useContext(AppContext);
  const classes = useStyles();
  const handleClose = () => {
    setOpenCalendar(false);
    setOpenMeetingInfo(false);
    if (page === 'welcomePage') {
      setIsHideScheduleButton();
    }
  };
  const isTrial =
    user?.studentTrial?.trialStartedAt && !user?.studentTrial?.trialCompletedAt
      ? true
      : false;

  const subtractFiveMinutes = (endTime: number) => {
    const fiveMinutes = 5 * 60;
    const date = new Date(endTime * 1000);
    date.setSeconds(date.getSeconds() - fiveMinutes);
    const updatedUnixTime = Math.floor(date.getTime() / 1000);
    return updatedUnixTime;
  };

  const meetingDate = moment
    .unix(meetingInfo.start_time)
    .format('dddd MMMM DD, YYYY');
  const startTime = moment.unix(meetingInfo.start_time).format('LT');
  const endTime = !isTrial
    ? moment.unix(meetingInfo.end_time).format('LT')
    : moment.unix(subtractFiveMinutes(meetingInfo.end_time)).format('LT');

  localStorage.setItem('meetingDate',  moment.unix(meetingInfo.start_time).format('MMMM DD, YYYY'));
  localStorage.setItem('startTime', startTime);
  localStorage.setItem('endTime', endTime);
  localStorage.setItem('timeZone', momentTz(meetingInfo.start_time * 1000).tz(momentTz.tz.guess()).format('z'));
  localStorage.setItem('advisor', meetingInfo.advisors.map((item) => item.name).join(','));

  const MEETING_DATA = [
    {
      title: 'Date and Time:',
      details: `${meetingDate}, ${startTime} - ${endTime} ${momentTz(
        meetingInfo.start_time * 1000
      )
        .tz(momentTz.tz.guess())
        .format('z')}`,
    },
    {
      title: 'Student Name:',
      details: meetingInfo.studentName,
    },
    {
      title: 'Advisor(s):',
      details: meetingInfo.advisors.map((item) => item.name).join(', '),
    },
    {
      title: 'Meeting Note:',
      details: meetingInfo.note,
    },
  ];

  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());
  const history = useHistory();

  useEffect(() => {
    if(page === 'welcomePage') {
      setOpenCalendar(false);  
    }
  },[]);

  return (
    <Dialog open={openMeetingInfo} onClose={handleClose} maxWidth="md">
      <DialogContent className={classes.meetingBookedContainer}>
        {page !== 'welcomePage' ? <Box textAlign="right" className={classes.closeButton}>
          <IconButton aria-label="close" onClick={handleClose}>
            <img src={CLOSE_ICON} alt="close" />
          </IconButton>
        </Box> : null}

        <Box
          display="flex"
          alignItems="center"
          pb={2.5}
          className="avatarHeading"
        >
          <Box width={130} display="flex" justifyContent="flex-end">
            <AvatarGroup max={2} className="avatarBigContainer">
              {meetingInfo.advisors.map((adv, index) => (
                <Avatar
                  key={`adv_image_${index}`}
                  src={adv.avatar || ''}
                  alt={adv.name || ''}
                  className="avatarBig"
                />
              ))}
            </AvatarGroup>
          </Box>

          <Box flex={1} pl={5} className={classes.advisingMeeting}>
            <Typography variant="h4">
              Your Advising Meeting Has Been Scheduled! {greenTickIc()}
            </Typography>
          </Box>
        </Box>
        { page === 'welcomePage' ? 
          <Box
            display="flex"
            alignItems="center"
            pb={2.5}
          >
            <Typography variant='body1'>
              Our team is excited to meet you and kick off your admissions journey! Advance to the Student Portal to review meeting details, explore colleges, and begin filling out your profile!
            </Typography>
          </Box> : null 
          }

        {MEETING_DATA.map((item, i) => (
          <Box
            display="flex"
            alignItems="flex-start"
            key={item.title}
            className={classes.scheduleDetail}
          >
            {i !== MEETING_DATA.length - 1 ? (
              <>
                <Box width={130} color={Colors.GRAY_TWENTYTWO}>
                  <Typography variant="h5" color="inherit">
                    {item.title}
                  </Typography>
                </Box>

                <Box flex={1} pl={5} className="valueTextBox">
                  <Typography variant="h5" className="valueText">
                    {item.details}
                  </Typography>
                </Box>
              </>
            ) : item?.details?.length && !isTrial ? (
              <>
                <Box width={130} color={Colors.GRAY_TWENTYTWO}>
                  <Typography variant="h5" color="inherit">
                    {item.title}
                  </Typography>
                </Box>

                <Box flex={1} pl={5} className="valueTextBox">
                  <Typography variant="body1" className="valueTextNote">
                    {item.details}
                  </Typography>
                </Box>
              </>
            ) : null}
          </Box>
        ))}
        { 
          page === 'welcomePage' ? 
          <Box
            display="flex"
            alignItems="center"
          >
            <Button
              className={classes.verticalCenter}
              onClick={() => {
                handleClose();
                if (params.userToken) {
                  history.push(`/reset-password/${params.userToken}?step=2`);
                } else {
                  history.push('/home');
                }
              }}
              color={'secondary'}
              variant="contained"
              >
              Go to Student Portal
            </Button>
          </Box>
          : null
        }
        {isTrial && (
          <>
            <Divider />
            <Box m="31px 0px 13px">
              <Typography variant="h4">Next Step:</Typography>
            </Box>

            <Box mb={3}>
              <Typography variant="body1" className="nextMeetingDesc">
                To make the most out of your meeting, please submit your
                responses to this questionnaire below.
              </Typography>
            </Box>

            <Button color="secondary" variant="contained">
              <a
                target={'_blank'}
                href={'https://airtable.com/shr0MNwjQXayNdnAQ'}
                style={{ color: '#fff' }}
              >
                Go to Questionnaire
              </a>
            </Button>
          </>
        )}
      </DialogContent>
    </Dialog>
  );
}
