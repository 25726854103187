import { useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { logUserActivity } from '../../../common/utility';
import {
  UserActivityActions,
  UserActivityType,
} from '../../../common/constants';
import { LocationInterface } from '../../webinar/interfaces';
import {
  Box,
  Card,
  Button,
  Typography,
  makeStyles,
  createStyles,
  Theme,
} from '@material-ui/core';

import { AppContext } from '../../../contexts';

import fonts from '../../../common/fonts';
import Colors from '../../../common/colors';

import Call_Image from '../../../img/call-phone-dark.svg';
import MEETING_YELLOW_ICON from '../../../img/meeting-yellow.svg';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    packageCard: {
      border: 0,
      marginBottom: 32,
      background: `linear-gradient(254.76deg, #FCA713 12.47%, #FDBD4E 95.84%)`,

      '& .phoneText': {
        fontWeight: 600,
        fontSize: 18,
        letterSpacing: '-0.014em',
        color: '#FFFFFF',
        fontFamily: fonts.INTER,
      },

      '@media only screen and (max-width: 1024px)': {
        minHeight: 200,
        display: 'flex',
        alignItems: 'center',
      },

      '& .cardContent': {
        padding: '38px 17px 39px 25px',

        [theme.breakpoints.down('sm')]: {
          padding: 20,
        },
      },

      '& h3, h6': {
        color: Colors.WHITE_ONE,
      },

      '& h3': {
        fontWeight: 'bold',
        fontSize: 28,
        marginBottom: 9,
        letterSpacing: '-0.019em',
      },

      '& h6': {
        fontWeight: 600,
        fontSize: 17,
        marginBottom: 25,
        lineHeight: '24px',
        letterSpacing: '-0.014em',
        fontFamily: fonts.INTER,
        textTransform: 'none',
      },

      '& p': {
        margin: 0,

        '& a': {
          color: Colors.WHITE_ONE,
        },
      },

      '& button': {
        color: Colors.YELLOW_FOUR,
        backgroundColor: Colors.WHITE_ONE,

        '&:hover': {
          backgroundColor: Colors.WHITE_ONE,
        },

        '&:focus': {
          backgroundColor: Colors.WHITE_ONE,
        },

        [theme.breakpoints.down('xs')]: {
          marginBottom: '15px !important',
        },
      },

      '& .buttonTextTransform': {
        textTransform: 'inherit',
      },

      '& .callNumber': {
        fontWeight: 600,
        fontSize: 18,
        letterSpacing: '-0.014em',
        color: '#FFFFFF',

        [`@media only screen and (min-width: 1026px) and (max-width: 1280px)`]:
          {
            paddingTop: 15,
          },

        '& .normalText': {
          fontWeight: 400,
        },
      },
    },
  })
);

const UpgradeCard = () => {
  const { user } = useContext(AppContext);
  const classes = useStyles();
  const currentYear = new Date().getFullYear();
  const { pathname, search, state }: LocationInterface = useLocation();

  const logActivity = () => {
    const query = new URLSearchParams(search);
    const origin = query.get('origin');
    const payload = {
      pageName: pathname,
      action: UserActivityActions.CLICKED_SCHEDULE_MEETING,
      type: UserActivityType.HOME_PAGE,
      origin: origin || state?.from || null,
    };
    logUserActivity(payload);
  };
  return (
    <Card className={classes.packageCard}>
      <Box className="cardContent">
        <Typography variant="h3">Upgrade Today</Typography>

        {(user?.highSchoolYear === (currentYear + 1).toString() ||
          user?.highSchoolYear === (currentYear + 2).toString() ||
          user?.highSchoolYear === (currentYear + 3).toString()) &&
        !user?.isPremium ? (
          <Typography variant="h6" className="updatedTypography">
            Schedule a free strategy meeting with an Admissions Specialist to
            learn more about our admissions packages.
          </Typography>
        ) : (
          <Typography variant="h6" className="updatedTypography">
            Talk to one of our Admissions Coordinators to learn more about our
            admissions packages.
          </Typography>
        )}

        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          flexWrap="wrap"
          paddingRight="15px"
        >
          {(user?.highSchoolYear === (currentYear + 1).toString() ||
            user?.highSchoolYear === (currentYear + 2).toString() ||
            user?.highSchoolYear === (currentYear + 3).toString()) &&
          !user?.isPremium ? (
            <>
              <Link to={'/schedule'}>
                <Button
                  variant="contained"
                  className="buttonTextTransform"
                  onClick={logActivity}
                >
                  <img src={MEETING_YELLOW_ICON} alt="call" />
                  Schedule a Meeting
                </Button>
              </Link>
              <Typography variant="body1" className="callNumber">
                <a href="tel: +1 (844) 343-6272">
                  <span className="normalText">or call</span> +1 (844) 343-6272
                </a>
              </Typography>
            </>
          ) : (
            <>
              <a href="tel: +1 (844) 343-6272">
                <Button variant="contained">
                  <img src={Call_Image} alt="call" />
                  Call Now
                </Button>
              </a>

              <a href="tel: +1 (844) 343-6272">
                <Typography variant="body1" className="callNumber">
                  <span className="normalText"></span> +1 (844) 343-6272
                </Typography>
              </a>
            </>
          )}
        </Box>
      </Box>
    </Card>
  );
};

export default UpgradeCard;
