// imports
import {
  Box,
  Grid,
  Theme,
  Button,
  Container,
  makeStyles,
  Typography,
  createStyles,
} from '@material-ui/core';
import { FC, useContext, useEffect, useState } from 'react';
import { RouteComponentProps, useLocation, withRouter } from 'react-router-dom';
// interfaces, constants
import {
  PackageShortNames,
  WELCOME_INSTRUCTIONS,
} from '../../../common/constants';
import client from '../../../apollo';
import { AppContext } from '../../../contexts';
import { cjConfig } from '../../../common/utility';
import { GET_PREMIUM_STUDENT_OF_PARENT, GET_USER_BY_TOKEN, GET_USER_BY_ID } from './gql';
// colors, fonts
import fonts from '../../../common/fonts';
import { default as Colors, default as colors } from '../../../common/colors';
// images
import Images from '../../../img';
import MBA_WELCOME_IMG from '../../../img/wlcom-mba.png';
import { DocumentThemeIcon } from '../../../common/assets/svgs';
import NEW_WELCOME_IMG from '../../../img/client-welcome-new.png';
import { AdvisorBookingModal } from '../home/StudentHomePage';
import Loader from '../../../components/Loader';
import { Query } from 'react-apollo';
import { ALL_ADVISOR_BOOKINGS_WELCOME } from '../home/BookingMeeting/gql';
import { allAdvisorBookingsQuery_allAdvisorBookings_advisorBookings } from '../home/BookingMeeting/gql/__generated__/allAdvisorBookingsQuery';
import moment from 'moment';
import momentTz from 'moment-timezone';
import { trim } from 'lodash';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    instructionsContainer: {
      padding: '30px 0 40px 0',

      '& > div': {
        '&:not(:last-child)': {
          paddingBottom: 37,
        },
      },
    },

    instructions: {
      display: 'flex',
      padding: '32px 0px',
      alignItems: 'flex-start',
      borderBottom: `1px solid ${colors.WHITE_SEVEN}`,

      '&:last-child': {
        borderBottom: 0,
      },

      '& h6': {
        textTransform: 'none',
        fontFamily: fonts.INTER,
        fontWeight: 700,

        '& span': {
          fontWeight: 'normal',
          marginLeft: 5,
        },
      },
    },

    instructionsScheduleCall: {
      display: 'flex',
      padding: '25px 25px 25px 25px',
      alignItems: 'flex-start',
      borderBottom: '1px solid transparent',

      borderRadius: 12,
      backgroundColor: '#EDFBF5',
      marginTop: 30,

      '&:last-child': {
        borderBottom: 0,
      },

      '& h6': {
        textTransform: 'none',
        fontFamily: fonts.INTER,
        fontWeight: 700,

        '& span': {
          fontWeight: 'normal',
          marginLeft: 5,
        },
      },
    },

    meetingBookedDetails: {
      display: 'flex',
      padding: '25px 25px 25px 25px',
      alignItems: 'flex-start',
      borderBottom: '1px solid transparent',

      borderRadius: 12,
      backgroundColor: '#EBF3FE',
      marginTop: 30,

      '&:last-child': {
        borderBottom: 0,
      },

      '& h6': {
        textTransform: 'none',
        fontFamily: fonts.INTER,
        fontWeight: 700,

        '& span': {
          fontWeight: 'normal',
        },
      },
    },

    callScheduleDetails: {
      '& a:hover': {
        cursor: 'pointer',
        textDecoration: 'underline',
        color: 'unset',
      },
      '& p': {
        fontSize: 14,
      },
    },

    instructionIcon: {
      minWidth: 24,
      width: 24,
      marginRight: 20,
    },

    buttonContainer: {
      paddingBottom: 40,

      '& button': {
        minWidth: 187,
      },
    },

    welcomeContainer: {
      paddingTop: 60,
    },

    welcomeFlex: {
      display: 'contents'
    },

    packageName: {
      textTransform: 'uppercase',
      display: 'inline-flex',
      alignItems: 'center',
      justifyContent: 'center',
    },

    description: {
      color: Colors.BLACK_FOURTEEN,
    },

    loginLink: {
      cursor: 'pointer',
      textDecoration: 'underline',
      color: 'unset',
    },

    bgWelcome: {
      backgroundImage: `url(${NEW_WELCOME_IMG})`,
      position: 'relative',
      minHeight: '800px',
      backgroundRepeat: 'no-repeat',
      backgroundPositionY: '80px',
      backgroundSize: 'contain',

      [theme.breakpoints.down('md')]: {
        width: '60vw',
        backgroundSize: 'contain',
        backgroundPositionX: 'center',
      },

      [theme.breakpoints.down('sm')]: {
        minHeight: 450,
        backgroundPosition: 'center top',
      },
    },
    bgMBAWelcome: {
      backgroundImage: `url(${MBA_WELCOME_IMG})`,
      maxWidth: '380px',
      marginLeft: '6vw',
      [theme.breakpoints.down('md')]: {
        marginLeft: '0px',
      },
    },
  })
);

const WelcomToPackage: FC<RouteComponentProps> = ({ history }) => {
  const classes = useStyles();

  const [user, setUser] = useState(useContext(AppContext)?.user);
  const [selectedStudent, setSelectedStudent] = useState(useContext(AppContext)?.user);
  const [AdmissionPackage, setAdmissionPackage] = useState<any>(
    user?.userSubscription?.SubscriptionPlan?.AdmissionPackage
  );
  const [parentIntakeToken, setParentIntakeToken] = useState('');
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());

  const [openCalendar, setOpenCalendar] = useState(false);
  const [advisorId, setAdvisorId] = useState<number | null>(null);
  const [defaultAdvisorIds, setDefaultAdvisorIds] = useState<number[]>([]);
  const intakeWidgetCondition = user?.roles?.includes('Parent') ? (selectedStudent?.isPremium && !selectedStudent?.initialKickOffDate && !selectedStudent?.firstMeetingBookedDate) : (user?.isPremium && !user?.initialKickOffDate && !user?.firstMeetingBookedDate);
  const [isMeetingBooked, setIsMeetingBooked] = useState(false);
  const [isTrialMeetingBooked, setIsTrialMeetingBooked] = useState(false);
  const [userLoggedIn, setUserLoggedIn] = useState(false);
  const [isHideScheduleButton, setIsHideScheduleButton] = useState(false);
  const [loading, setLoading] = useState(false);
  const [meetingBookedData, setMeetingBookedData] = useState<allAdvisorBookingsQuery_allAdvisorBookings_advisorBookings[] | null>(null);
  const [callDate, setCallDate] = useState<any | null>(null);
  const [callStartTime, setCallStartTime] = useState<any | null>(null);
  const [callEndTime, setCallEndTime] = useState<any | null>(null);
  const [callTimeZone, setCallTimeZone] = useState<any | null>(null);
  const [primaryAdvisor, setPrimaryAdvisor] = useState<any | null>(null);
  const [admissionAdvisor, setAdmissionAdvisor] = useState<any | null>(null);

  const location = useLocation();
  const isSurveyForm = location.pathname.includes('survey-form');
  const isMeetingSetPage = location.pathname.includes('schedule');
  const isTrial =
    user?.studentTrial?.trialStartedAt && !user?.studentTrial?.trialCompletedAt
      ? true
      : false;

  const localStoredSmartMatchId = localStorage.getItem('smartMatchId') !== 'undefined' ? localStorage.getItem('smartMatchId') : false;
  const localStoredStudentId = localStorage.getItem('selectedStudent') !== 'undefined' ? localStorage.getItem('selectedStudent') : false;

  const goToHomePage = () => {
    setOpenCalendar(true);
  };

  // MBA_CONSTANT: Also check the user of isMBAGrad.
  const isMBAGrad = Boolean(user?.isMBAGrad);
  const isSmartMatchId = Boolean(user?.SmartMatchId) || Boolean(localStoredSmartMatchId);

  const handleLetsGo = () => {
    const email: any = user?.email;
    const emailEncoded = encodeURIComponent(email); // encode the email
    if (isMBAGrad) {
      // block: MBAGrad without password
      history.push(`/reset-password/${params.userToken}?step=2`);
    } else {
      if(!params.userToken) {
        history.push(
          `/intake-form/${parentIntakeToken || user?.intakeFormToken}?email=${emailEncoded}`
        );
      } else {
        history.push(
          `/intake-form/${parentIntakeToken || user?.intakeFormToken}?userToken=${params.userToken}&email=${emailEncoded}`
        );
      }
    }
  };

  const getBookedMeetingDetails = (stdId: Number) => {
    if (stdId) {
      try {
        const alreadyBookedMeeting = async () => {
          const { data } = await client.query({
            query: ALL_ADVISOR_BOOKINGS_WELCOME,
            variables: {
              status: 'upcoming',
              sortByDate: true,
              studentId: stdId,
            },
          });
          const response = data?.allAdvisorBookingsWelcome;
          if (response.count > 0) {
            setMeetingBookedData(response?.advisorBookingsWelcome);
            setCallDate(moment.unix(response?.advisorBookingsWelcome[0].startTime).format('MMMM DD, YYYY'));
            setCallStartTime(moment.unix(response?.advisorBookingsWelcome[0].startTime).format('LT'));
            setCallTimeZone(momentTz(response?.advisorBookingsWelcome[0].startTime * 1000).tz(momentTz.tz.guess()).format('z'));
            setCallEndTime(moment.unix(response?.advisorBookingsWelcome[0].endTime).format('LT'));
            const primary = response?.advisorBookingsWelcome[0].allParticipants.find((participant:any) => {return participant.id === response?.advisorBookingsWelcome[0].AdvisorId});
            const secondary = response?.advisorBookingsWelcome[0].allParticipants.find((participant:any) => {return participant.id !== response?.advisorBookingsWelcome[0].AdvisorId && participant.roles.includes('Mentor')});
            setPrimaryAdvisor(`${primary?.firstName} ${primary?.lastName}`);
            if (secondary?.firstName) {
              setAdmissionAdvisor(`${secondary?.firstName} ${secondary?.lastName}`);
            } else {
              setAdmissionAdvisor(null);
            }
            
            setIsHideScheduleButton(true);
            localStorage.setItem('scheduleButton', 'hide');
            setLoading(true);
          }
        };
        alreadyBookedMeeting();
      } catch (err) {
        console.log(err);
      }
    }
  };

  const getUserByToken = () => {
    if (params.userToken && !user) {
      try {
        const apiCalling = async () => {
          const { data } = await client.query({
            query: GET_USER_BY_TOKEN,
            variables: { userToken: params.userToken },
          });
          if (data && data.getUserByToken) {
            setUser(data.getUserByToken);
          } 
          if (data && data.getUserByToken && data.getUserByToken.roles.includes('Student')) {
            getBookedMeetingDetails(data?.getUserByToken?.id)
          }
          if (data && data.getUserByToken && data.getUserByToken.roles.includes('Parent') && Boolean(localStoredStudentId)) {
            const selectedStudentData = await client.query({
              query: GET_USER_BY_ID,
              variables: { StudentId: Number(localStoredStudentId) },
            });
            if (selectedStudentData?.data && selectedStudentData?.data?.getUserById?.id) {
              setSelectedStudent(selectedStudentData?.data?.getUserById);
              getBookedMeetingDetails(selectedStudentData?.data?.getUserById.id);
            }
          }
        };
        apiCalling();
      } catch (err) {
        console.log(err);
      }
    } else {
      try {
        const loggedUser = async () => {
          if (user?.roles?.includes('Student')) {
            getBookedMeetingDetails(user?.id);
          } else {
            const selectedStudentData = await client.query({
              query: GET_USER_BY_ID,
              variables: { StudentId: Number(localStoredStudentId) },
            });
            if (selectedStudentData?.data && selectedStudentData?.data?.getUserById?.id) {
              setSelectedStudent(selectedStudentData?.data?.getUserById);
              getBookedMeetingDetails(selectedStudentData?.data?.getUserById.id);
              }
          }
        }
        loggedUser();
      } catch (e) {
        console.log(e);
      }
    }
  };

  const getPremiumStudentOfParent = (ParentId: number) => {
    try {
      const apiCalling = async () => {
        const StudentId = localStorage.getItem('PremiumStudentId');
        if (StudentId) {
          const { data } = await client.query({
            query: GET_PREMIUM_STUDENT_OF_PARENT,
            variables: { StudentId: parseInt(StudentId), ParentId },
          });

          if (data && data?.getStudentPackageAndParentToken) {
            const dataObj = data?.getStudentPackageAndParentToken;
            if (dataObj?.admissionPackage) {
              setAdmissionPackage(dataObj?.admissionPackage);
            }
            if (dataObj?.parentIntakeFormToken) {
              setParentIntakeToken(dataObj?.parentIntakeFormToken);
            }
          }
        }
      };
      apiCalling();
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(getUserByToken, []);
  const refetchMeetings = () => {
    setIsMeetingBooked(true);
  };

  const hideScheduleButton = () => {
    setIsHideScheduleButton(true);
    localStorage.setItem('scheduleButton', 'hide');
    const meetingDate = localStorage.getItem('meetingDate');
    setCallDate(meetingDate);
    const startTime = localStorage.getItem('startTime');
    setCallStartTime(startTime);
    const endTime = localStorage.getItem('endTime');
    setCallEndTime(endTime);
    const timeZone = localStorage.getItem('timeZone');
    setCallTimeZone(timeZone);
    const advisor = localStorage.getItem('advisor')?.split(',');
    if (advisor && advisor?.length > 0) {
      setPrimaryAdvisor(trim(advisor[0]));
      if (advisor?.length > 1) {
        setAdmissionAdvisor(trim(advisor[1]));
      }
    }
  };

  useEffect(() => {
    if (user?.isParent) {
      getPremiumStudentOfParent(user.id);
    } else {
      if (user?.userSubscription) {
        const aPackage =
          user?.userSubscription?.SubscriptionPlan?.AdmissionPackage;
        setAdmissionPackage(aPackage);
      }
    }
  }, [user]);

  useEffect(() => {
    setTimeout(() => {
      setLoading(true);
    }, 6000);
  },[])

  //cj convertion tag
  useEffect(() => {
    if (user?.userSubscription?.SubscriptionPlan?.amount) {
      cjConfig({
        userId: user?.id,
        orderId: user?.userSubscription?.stripeSubscriptionId,
        amount: user?.userSubscription?.SubscriptionPlan?.amount / 100,
        itemId: user?.userSubscription?.SubscriptionPlan?.AdmissionPackage?.id,
        coupon: user?.userCoupon?.stripeCouponId || '',
        discount: user?.userCoupon?.amount_off
          ? user?.userCoupon?.amount_off / 100
          : user?.userCoupon?.percent_off
          ? (user?.userSubscription?.SubscriptionPlan?.amount *
              (user?.userCoupon?.percent_off / 100)) /
            100
          : 0,
        isInstallment:
          user?.userSubscription?.SubscriptionInstallments?.length > 0
            ? true
            : false,
      });
    }
    if (user && !user.isParent && !isSurveyForm && !isMeetingSetPage && !isTrial &&!user.isMBAGrad) {
      setUserLoggedIn(true);
    } else {
      setUserLoggedIn(false);
    }
  }, []);

  if (!AdmissionPackage) {
    return null;
  }

  const onClickLogin = () => {
    if (user?.roles?.includes('Parent')) {
      history.push(`/reset-password/${params.userToken}?step=2`);
    } else {
      history.push('/home');
    }
  };

  return (
    <Container maxWidth={false}>
      <Grid container spacing={2}>
        { loading ? 
          <Grid className={classes.welcomeFlex}>
            <Grid item xs={12} lg={7}>
              <Box className={classes.welcomeContainer} pb={4}>
                <Box display="flex" alignItems="center" marginBottom={1.2}>
                  <Typography variant="h3">Welcome to</Typography>
                  {isMBAGrad ? (
                    <>
                      <Box
                        component="span"
                        color={AdmissionPackage?.color?.split(',')?.[0]}
                        marginLeft={1}
                      >
                        <Typography variant="h3" color="inherit">
                          {AdmissionPackage?.shortName?.split(' ')?.[0]}
                        </Typography>
                      </Box>
                      <Box
                        component="span"
                        color={AdmissionPackage?.color?.split(',')?.[1]}
                        marginLeft={1}
                      >
                        <Typography variant="h3" color="inherit">
                          {AdmissionPackage?.shortName
                            ?.split(' ')?.[1]
                            ?.toLowerCase()}
                        </Typography>
                      </Box>
                    </>
                  ) : (
                    <Box
                      component="span"
                      color={AdmissionPackage?.color}
                      marginLeft={1}
                      className={classes.packageName}
                    >
                      <Typography variant="h3" color="inherit">
                        {AdmissionPackage?.shortName}
                      </Typography>
                      {AdmissionPackage?.shortName === PackageShortNames.DIAMOND ? (
                        <Box ml={1}>
                          <img height="26px" src={Images.DiamondIcon2} />
                        </Box>
                      ) : null}
                    </Box>
                  )}

                  {AdmissionPackage?.shortName !== PackageShortNames.DIAMOND ? (
                    isMBAGrad ? (
                      <Box color={Colors.GREEN_EIGHT} marginLeft={2}>
                        <img height="42px" width="42px" src={Images.MBA_CHECK} />
                      </Box>
                    ) : (
                      <Box color={Colors.GREEN_EIGHT} marginLeft={2} maxWidth={24}>
                        <img height="26px" width="26px" src={Images.CHECKED_1} />
                      </Box>
                    )
                  ) : null}
                </Box>
                <Box color={Colors.BLACK_THIRTEEN}>
                  <Box width={'500px'}>
                    <Typography variant="body2" color="inherit">
                      Ready to maximize your admissions odds? Please complete the
                      following tasks to begin your {isMBAGrad ? '' : 'college'}{' '}
                      journey with us:
                    </Typography>
                  </Box>

                  <Box maxWidth={640}>
                    {localStorage.getItem('scheduleButton') !== 'hide' && isSmartMatchId && !isHideScheduleButton && (
                      <Box className={classes.instructionsScheduleCall}>
                        <Box className={classes.instructionIcon}>
                          <DocumentThemeIcon />
                        </Box>

                        <Box>
                          <Typography variant="h6">
                            Schedule your Orientation Call 
                            <span className={classes.description}>
                             by selecting the button below, or by logging into the Student Portal.
                            </span>
                          </Typography>

                          <Box pt={3}>
                            <Button
                              color="secondary"
                              variant="contained"
                              onClick={goToHomePage}
                            >
                              Schedule
                            </Button>
                          </Box>
                        </Box>
                        {
                          isSmartMatchId ? 
                          <AdvisorBookingModal
                            openCalendar={openCalendar}
                            setOpenCalendar={setOpenCalendar}
                            setIsMeetingBooked={setIsMeetingBooked}
                            advisorId={advisorId}
                            refetch={refetchMeetings}
                            intakeWidgetCondition={intakeWidgetCondition || false}
                            setIsTrialMeetingBooked={setIsTrialMeetingBooked}
                            defaultAdvisorIds={defaultAdvisorIds}
                            setAdvisorId={setAdvisorId}
                            page="welcomePage"
                            user={user?.roles && user?.roles.includes('Parent') ? selectedStudent : user}
                            setIsHideScheduleButton={hideScheduleButton}
                          /> : null
                        }
                      </Box>
                    )}
                    { (meetingBookedData && meetingBookedData.length > 0) || isHideScheduleButton ? 
                        <Box className={classes.meetingBookedDetails}>
                        <Box className={classes.instructionIcon}>
                          <img height="18px" width="18px" src={Images.BLUE_TICK} />
                        </Box>

                        <Box>
                          <Typography className={classes.callScheduleDetails}>
                            {
                              admissionAdvisor ? 
                                <Typography>
                                  <Typography>You have scheduled your orientation call for</Typography><br />
                                  <Typography><b>{callDate}</b> at <b>{callStartTime.toLowerCase()} - {callEndTime.toLowerCase()} ({callTimeZone})</b> with <b>{primaryAdvisor}</b> and <b>{admissionAdvisor}</b>.<br /><br /></Typography>
                                </Typography> : 
                                <Typography>
                                  <Typography>You have scheduled your orientation call for</Typography><br />
                                  <Typography><b>{callDate}</b> at <b>{callStartTime.toLowerCase()} - {callEndTime.toLowerCase()} ({callTimeZone})</b> with <b>{primaryAdvisor}</b>.<br /><br /></Typography>
                                </Typography>
                            }
                            <Typography>Please check your email or <a className={classes.loginLink} onClick={onClickLogin}>log in</a> to the Student Portal to view meeting details.</Typography>
                          </Typography>
                        </Box>
                    </Box>: null
                    }

                    {WELCOME_INSTRUCTIONS(isMBAGrad, isSmartMatchId).map((item) => (
                      <Box className={classes.instructions} key={item.title}>
                        <Box className={classes.instructionIcon}>{item.image}</Box>

                        <Box>
                          <Typography variant="h6">
                            {item.title}
                            <span className={classes.description}>
                              {item.description}
                            </span>
                          </Typography>
                        </Box>
                      </Box>
                    ))}
                  </Box>
                </Box>
                {!isSmartMatchId && !isMeetingBooked && (
                  <Box className={classes.buttonContainer}>
                    <Button
                      color="secondary"
                      variant="contained"
                      onClick={handleLetsGo}
                    >
                      Let’s Go!
                    </Button>
                  </Box>
                )}

                <Box display="flex">
                  <Box marginRight={2}>
                    <img src={Images.QUESTIONS} alt="" />
                  </Box>

                  <Box>
                    <Typography variant="body1">
                      <strong>Questions?</strong>
                    </Typography>
                    <Typography variant="body2">
                      Please call <a href="tel:+1 (855) 412-6272">(855) 412-6272</a>
                      , or feel free to email us at{' '}
                      <a href="mailto:success@collegeadvisor.com">
                        success@collegeadvisor.com
                      </a>
                      . We look forward to supporting you throughout your admissions
                      journey.
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Grid>

            <Grid item xs={12} lg={5} alignItems="center">
              <div
                className={
                  (isMBAGrad ? classes.bgMBAWelcome + ' ' : '') + classes.bgWelcome
                }
              ></div>
            </Grid>
          </Grid> : <Loader />}
      </Grid>
    </Container>
  );
};

export default withRouter(WelcomToPackage);
