// imports
import { makeStyles, createStyles, Theme } from '@material-ui/core';
// styles
import fonts from '../../../../common/fonts';
import colors from '../../../../common/colors';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    noTimeSlots: {
      height: '100%',
      display: 'flex',
      textAlign: 'center',
      alignItems: 'center',
      justifyContent: 'center',

      "& [class*='MuiTypography-body2']": {
        fontWeight: 400,
        marginBottom: 10,
        letterSpacing: '-0.084px',
        color: colors.BLACK_FOURTEEN,
      },

      '& .bookPrimaryAdvisor': {
        fontSize: 12,
        fontWeight: 400,
        cursor: 'pointer',
        lineHeight: '20px',
        fontFamily: fonts.INTER,
        color: colors.BLUE_SEVEN,
        letterSpacing: '-0.072px',
        borderBottom: `1px solid ${colors.BLUE_SEVEN}`,
      },

      '& .infoIcon': {
        padding: 2,
        marginLeft: 5,
        color: colors.WHITE_SEVEN,
      },
    },

    addAdvisor: {
      padding: '29px 50px 35px',

      [theme.breakpoints.down('sm')]: {
        padding: 15,
      },

      '& .backButton': {
        height: 40,
        marginLeft: -9,
        cursor: 'pointer',
      },
    },

    advisorScreenBottom: {
      borderTop: 'none !important',
    },
    
    verticalCenter: {
      margin: '0 auto',
      display: 'block',
    },

    dialogPaperHeader: {
      padding: '26px 38px 26px 50px',
      borderBottom: `1px solid ${colors.WHITE_SEVEN}`,

      [theme.breakpoints.down('sm')]: {
        padding: 15,
      },
    },

    advisingMeeting: {
      [theme.breakpoints.down('xs')]: {
        maxWidth: '100%',
        padding: 0,
      },

      '& svg': {
        marginLeft: 20,
      },

      '& h4': {
        fontWeight: 'bold',
        fontSize: 36,
        letterSpacing: '-0.022em',
        color: '#272929',
        lineHeight: '42px',

        [theme.breakpoints.down('sm')]: {
          fontSize: 18,
          lineHeight: '25px',
          paddingRight: 20,
        },

        '& img': {
          [theme.breakpoints.down('sm')]: {
            width: 18,
          },
        },
      },

      '& .valueTextBox': {
        width: 392,
      },
    },

    disabledLabel: {
      opacity: `0.5`,
      cursor: 'not-allowed !important',
      color: 'rgba(0, 0, 0, 0.26)',
      boxShadow: 'none',
      backgroundColor: 'rgba(0, 0, 0, 0.12) !important',
    },

    advisorCard: {
      '& .advisorInfoCard': {
        boxShadow: 'none',
        border: `1px solid ${colors.BLUE_SEVEN}`,
        borderRadius: 20,
        cursor: 'pointer',
        padding: '12px 21px',

        [theme.breakpoints.down('sm')]: {
          marginBottom: 20,
        },

        '& .advisorButton': {
          visibility: 'hidden',
          padding: 12,
          marginRight: -12,
        },

        '&:hover': {
          background: colors.WHITE_EIGHT,

          '& .advisorButton': {
            visibility: 'visible',
          },
        },

        '& .advisorAvatar': {
          height: 47,
          width: 47,
          marginRight: 18,
        },
      },
    },

    datePickerWrapper: {
      padding: '49px 50px 10px',
      background: 'rgba(244, 244, 244, 0.5)',
      borderTop: `1px solid ${colors.WHITE_SEVEN}`,

      [theme.breakpoints.down('sm')]: {
        padding: 15,
      },
    },

    radioGroupGrid: {
      position: 'relative',
      marginRight: 14,

      [theme.breakpoints.down('sm')]: {
        marginRight: 10,
        width: '46%',
      },
    },

    radioGroupSlots: {
      [theme.breakpoints.down('sm')]: {
        margin: 0,
        width: '100%',
        padding: '0px 15px',
        overflowX: 'hidden',
      },
    },

    radioGroup: {
      display: 'flex',
      alignItems: 'center',

      [theme.breakpoints.down('sm')]: {
        flexWrap: 'wrap',
        alignItems: 'flex-start',
      },

      '& input[type="radio"]': {
        visibility: 'hidden',
        height: 34,
        width: '125px',
        position: 'absolute',
      },

      '& label': {
        margin: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: 34,
        width: 125,
        fontSize: 14,
        fontWeight: 600,
        borderRadius: 12,
        cursor: 'pointer',
        color: colors.BLACK_TWELVE,
        letterSpacing: '-0.006em',
        backgroundColor: colors.WHITE_ONE,
        border: `1px solid ${colors.WHITE_SEVEN}`,
        fontFamily: fonts.POPPINS_FONT,

        [theme.breakpoints.down('sm')]: {
          marginBottom: 15,
          width: '100%',
        },
      },

      '& input[type="radio"]:checked + label': {
        backgroundColor: colors.BLUE_SEVEN,
        color: colors.WHITE_ONE,
        border: `1px solid ${colors.BLUE_SEVEN}`,
        transition: 'all .3s ease-out',
      },
    },

    meetingRadioContainer: {
      padding: '43px 50px',
      borderTop: `1px solid ${colors.WHITE_SEVEN}`,

      [theme.breakpoints.down('sm')]: {
        padding: 15,
      },
    },

    availableTimeSlots: {
      paddingLeft: '50px !important',
      margin: '65px 0px 0px 0',

      [theme.breakpoints.down('sm')]: {
        margin: 0,
        paddingLeft: '16px !important',
      },

      '& input[type="radio"]': {
        height: 40,
        width: '100%',
      },

      '& label': {
        width: '100%',
        height: 40,
        marginBottom: 13,
        fontFamily: fonts.POPPINS_FONT,
      },
    },

    radioNotFlex: {
      display: 'initial',
    },

    closeButton: {
      position: 'absolute',
      right: 45,
      top: 44,

      [theme.breakpoints.down('xs')]: {
        right: 10,
        top: 0,
      },
    },

    meetingBookedContainer: {
      padding: '58px 55px 58px  51px !important',

      [theme.breakpoints.down('xs')]: {
        padding: '30px 12px !important',
      },

      '& .nextMeetingDesc': {
        fontWeight: 400,
        color: colors.BLACK_FOURTEEN,
      },

      '& .avatarBig': {
        width: 72,
        height: 72,
        marginLeft: 'auto',
        border: 0,

        [theme.breakpoints.down('xs')]: {
          marginLeft: 0,
        },
      },

      '& .avatarHeading': {
        [theme.breakpoints.down('sm')]: {
          paddingBottom: 10,
        },
      },
    },

    scheduleDetail: {
      paddingBottom: 24,

      '&:last-child': {
        paddingBottom: 0,
      },

      '& h5': {
        maxWidth: 392,
      },

      [theme.breakpoints.down('sm')]: {
        alignItems: 'flex-start',
      },

      '& .valueTextNote': {
        fontWeight: 400,
        fontSize: 14,
        letterSpacing: '-0.006em',
      },
    },

    bookAppointment: {
      '& .menu-button': {
        padding: 0,
        minWidth: 40,
        height: 40,
        borderRadius: '50%',

        '& img': {
          margin: 0,
        },
      },

      '& .booking-card': {
        marginBottom: 21,
        padding: '27px 32px',

        [theme.breakpoints.down('sm')]: {
          padding: 15,
        },

        '&:hover': {
          background: colors.GRAY_BOREDER,
        },
      },

      '& .booking-card-disabled': {
        background: colors.GRAY_BOREDER,
        cursor: 'not-allowed',
      },

      '& .avatar-group': {
        width: 100,

        [theme.breakpoints.down('sm')]: {
          width: 'auto',
          margin: '3px 10px 0px 0px',
        },
      },

      '& .past-meetings': {
        borderTop: `1px solid ${colors.WHITE_SEVEN}`,
      },

      '& .box-spacing': {
        [theme.breakpoints.down('sm')]: {
          padding: '15px 0px',
        },
      },

      '& .boxSpacingTypo': {
        [theme.breakpoints.down('sm')]: {
          padding: '40px 0px 50px',
        },
      },

      '& .mobile-flex-main': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',

        [theme.breakpoints.down('sm')]: {
          flexDirection: 'column',
          width: '100%',
        },
      },

      '& .mobile-flex-direction': {
        [theme.breakpoints.down('sm')]: {
          flexDirection: 'column',
          width: '100%',

          '&:first-child': {
            alignItems: 'flex-start',
          },
        },
      },

      '& .text-box-spacing': {
        [theme.breakpoints.down('sm')]: {
          margin: '10px 0px 0px 0px',
        },
      },

      '& .user-avatar': {
        width: 33,
        height: 33,
      },

      '& .avatar-disabled': {
        filter: 'grayscale(1)',
      },

      '& .avatarBigContainer': {
        justifyContent: 'flex-end',

        "& [class*='MuiAvatarGroup-avatar']": {
          width: 72,
          height: 72,
          fontSize: 36,
        },
      },

      '& .pagination-wrapper': {
        textAlign: 'right',
      },

      '& .pagination-spacing': {
        padding: 0,

        '& button': {
          padding: 5,
        },

        '& svg': {
          width: 25,
          height: 25,
        },
      },
    },

    mobileAlignment: {
      display: 'flex',
      width: '100%',
      justifyContent: 'flex-end',
    },

    bookingTable: {
      overflow: 'auto',
      width: '100%',
    },

    bookingTableRow: {
      border: '1px solid #D4D4D4',
      borderRadius: 15,
      boxShadow: '0px 2px 7px rgba(68, 68, 68, 0.14)',

      '& .participantName': {
        whiteSpace: 'break-spaces',
      },
    },

    bookingTableWrapper: {
      borderCollapse: 'separate',
      borderSpacing: '0px 9px',
      '& tbody': {
        '& tr': {
          '& td': {
            padding: '8px 15px 9px 23px',
            backgroundColor: colors.WHITE_ONE,
            border: `1px solid ${colors.WHITE_SEVEN}`,
            fontFamily: fonts.POPPINS_FONT,
            borderLeft: 0,
            borderRight: 0,
            fontWeight: 300,
            whiteSpace: 'nowrap',
            minHeight: 52,
            fontSize: 14,

            '& h6': {
              fontFamily: fonts.INTER,
              fontSize: 16,
              fontWeight: 400,
              letterSpacing: '-0.011em',
              color: colors.BLACK_TWELVE,
              textTransform: 'capitalize',
            },

            '& p': {
              fontFamily: fonts.INTER,
              fontSize: 14,
              fontWeight: 400,
              letterSpacing: '-0.011em',
              color: '#272929',
            },

            '&:first-child': {
              borderRadius: '15px 0px 0px 15px',
              borderLeft: `1px solid #D4D4D4`,
            },
            '&:last-child': {
              borderRadius: '0px 15px 15px 0px',
              borderRight: `1px solid #D4D4D4`,
            },

            "& [class*='.MuiAvatar-root']": {
              width: 33,
              height: 33,
            },
          },
        },
      },
    },

    tableHead: {
      fontFamily: fonts.POPPINS_FONT,
      fontSize: 14,
      fontWeight: 600,
      letterSpacing: '0.006em',
      color: colors.BLACK_TWELVE,
      textTransform: 'uppercase',
      whiteSpace: 'nowrap',
      padding: '0px 22px',
    },

    emptyTableCell: {
      fontFamily: fonts.INTER,
      background: '#F4F4F4 !important',
      borderRadius: '10px !important',
      border: '0px !important',
      padding: '32px 45px !important',
      fontSize: '16px !important',
      letterSpacing: '-0.011em !important',
      color: '#A9A9A9 !important',
    },

    mobileMeetingsView: {
      padding: '14px 16px',
      borderRadius: 10,
      border: '1px solid #D4D4D4',
      marginBottom: 15,
      boxShadow: '0px 2px 7px rgba(68, 68, 68, 0.07)',

      '& h6, p': {
        color: '#272929',
        fontFamily: fonts.INTER,
      },

      '& h6': {
        fontSize: 18,
        fontWeight: 600,
        letterSpacing: '-0.014em',
        textTransform: 'capitalize',
      },

      '& p': {
        fontSize: 16,
        letterSpacing: '-0.011em',
      },

      '& a': {
        fontSize: 16,
        color: '#2F80ED',
        fontFamily: fonts.INTER,
        letterSpacing: '-0.011em',
        textDecorationLine: 'underline !important',
      },

      '& .linkSpacing': {
        marginBottom: 11,
      },

      '& .MuiIconButton-root': {
        padding: 5,
      },
    },

    meetingNotesDialog: {
      padding: '38px 42px 48px',

      '& .dialogHeading': {
        fontSize: 24,
        fontWeight: 600,
        color: '#272929',
        letterSpacing: '-0.014em',
      },

      '& .dialogSubtitle': {
        fontWeight: 400,
        fontSize: 14,
        letterSpacing: '-0.006em',
        color: '#7D7F7F',
      },
    },

    meetingNoteArea: {
      padding: '30px 0px 0px',

      '& .meetingGrid': {
        width: '100%',
        borderTop: '1px solid #d4d4d4',
        paddingTop: 30,
        marginTop: 10,
      },

      '& .dialogHeading': {
        fontWeight: 600,
        fontSize: 18,
        letterSpacing: '-0.014em',
        color: '#272929',
        fontFamily: fonts.INTER,
        marginBottom: 10,
      },

      '& .dialogSubtitle': {
        fontWeight: 400,
        fontSize: 14,
        letterSpacing: '-0.006em',
        color: '#7D7F7F',
        marginTop: 5,
        fontFamily: fonts.INTER,
      },

      '& textarea': {
        border: '1px solid #D4D4D4',
        width: '100%',
        borderRadius: 5,
        padding: '8px 12px',
        fontWeight: 400,
        fontSize: 14,
        letterSpacing: '-0.006em',
        color: '#272929',
        fontFamily: fonts.INTER,
        height: 82,
        overflowY: 'auto',
      },

      '& [class*="MuiInputBase-root"]': {
        padding: 0,
        width: '100%',
        paddingTop: 17,
      },

      '& [class*="MuiTypography-caption"]': {
        fontSize: 14,
        fontWeight: 400,
        display: 'block',
        paddingBottom: 4,
        color: '#7D7F7F',
        fontFamily: fonts.INTER,
        letterSpacing: '-0.084px',
      },

      '& .select__multi-value__remove, & .select__clear-indicator': {
        display: 'none',
      },

      '& .select__multi-value__label': {
        padding: '3px 8px',
      },

      '& .select__placeholder': {
        fontSize: 14,
        fontWeight: 400,
        color: '#A9A9A9',
        fontFamily: fonts.INTER,
        letterSpacing: '-0.084px',
      },

      '& .select__control': {
        maxHeight: 70,
        overflowY: 'auto',
      },

      '& .select__input': {
        fontSize: 14,
      },
    },

    dialogActionText: {
      justifyContent: 'space-between !important',

      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
        alignItems: 'flex-end',
      },

      '& .advisorTextGrid': {
        maxWidth: 375,

        [theme.breakpoints.down('xs')]: {
          maxWidth: '100%',
        },
      },

      '& .headingText': {
        textTransform: 'capitalize',
        fontFamily: fonts.INTER,
      },

      '& .subtitleText': {
        fontWeight: 400,
        fontSize: 14,
        letterSpacing: '-0.006em',
        color: '#7D7F7F',
      },
    },
  })
);
